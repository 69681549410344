:root {
  --wheel-font: 'sora', 'Poppines-Bold', sans-serif;
  --wheel-size: 79vw;
  --wheel-slice-spacing: 9.5px;
  --wheel-border-size: 5px;
  /* --wheel-color: #1c7eff; */
  --wheel-color: black;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --spinning-duration: 3000ms; /* Duration for spinning */
  --wheel-bottom: 12vh; /* Default bottom position */
  --wheel-right: 0vw; /* Default right position */
  --wheel-left: 2.5vw; /* Default left position */
}

/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
  display: block;
  position: relative;
  bottom: var(--wheel-bottom);
  right: var(--wheel-right);
  left: var(--wheel-left);
  box-sizing: content-box;
  /* width: calc(var(--wheel-size) + 2 * var(--wheel-border-size)); */
  /* height: calc(var(--wheel-size) + 2 * var(--wheel-border-size)); */
  padding: 3px;
  background-color: #0000008c;
  border: solid #ff9926 11px;
  filter: drop-shadow(0 0 26px #9e27fff0) drop-shadow(0 0 5px #9e27fff0);
  box-shadow: -1px 1px 20px 0px #ff9926, inset 0 0 26px #ff9926, inset 0 0 25px #ff9926;
  border-radius: 50%;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
}

/* Triangle de sélection = 1 rose + 1 blanc */
.wheel-container::before,
.wheel-container::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: solid transparent 20px;
  border-left-width: 0;
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
}

/* Roue */
.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  /* border: solid #24002d 6px; */
  background-color: #ff4d4d;
  /* background: url('../../assets/images/spin.png'); */
  /* background-size: cover; */
  transition: transform var(--spinning-duration);
  transform: rotate(0deg);
  cursor: none;
}

/* Centre de la roue = rond blanc au centre */
.wheel::after {
  display: block;
  position: absolute;
  content: '';
  background-color: var(--neutral-color);
  width: 25px;
  height: 25px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

/* Element sur la roue */
.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;

  /* Position de l'item */
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item))));

  /* Texte */
  color: var(--neutral-color);
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
  font-weight: 800;
}

/* Background de l'élément = triangle rose plus clair */
.wheel-item:before {
  content: ' ';
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.85;
  --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
  --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  border-right: solid var(--border-color) calc(var(--wheel-size) / 2);
}

.spin-button-demo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 16px;
    background: #ffc078;
    color: white;
    font-family: 'Sora';
    font-weight: 700;
    border: none;
    border-radius: 70px;
    cursor: none;
    width: 55px;
    height: 56px;
    z-index: 3;
    /* border: 5px solid #ff9926; */
    box-shadow: inset 7px 7px 16px 5px #ff7205, 0px 0px 25px #b66000;
    /* border-image: linear-gradient(35deg, red, blue); */
}

.spin-button-demo:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.prize {
  color: #fff;
  font-family: 'Sora';
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
  width: 100%;
  border: 4px dashed white;
  border-radius: 20px;
  position: relative;
  top: 40px;
}
