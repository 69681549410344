.spin-button-container {
    position: fixed;
    bottom: 14vh;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .spin-button {
    width: 225px;
    height: 57px;
    background: linear-gradient(to right, #7c3aed, #5b21b6);
    border-radius: 9999px;
    padding: 2px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 0 5px rgb(255 165 0), 0 0 24px 4px rgb(255 165 0), inset 0px 10px 15px #9b6707a8, 0 4px 6px rgb(0 0 0 / 73%);
    transition: all 0.3s ease;
}
  
  .spin-button:hover {
    box-shadow: 0 0 0 2px rgba(255,165,0,0.7), 0 6px 8px rgba(0,0,0,0.15);
  }
  
  .spin-button-circle {
    width: 56px;
    height: 56px;
    background: linear-gradient(135deg, #a78bfa, #7c3aed);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
    position: relative;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    user-select: none;
  }
  
  .spin-button-circle:active {
    cursor: grabbing;
  }
  
  .spin-button-inner-circle {
    width: 50px;
    height: 50px;
    background: linear-gradient(135deg, #c4b5fd, #8b5cf6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .spin-button-number {
    /* position: absolute; */
    color: white;
    font-weight: bold;
    font-size: 1.25rem;
    /* left: 13px; */
    user-select: none;
  }
  
  .spin-button-text {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 1.125rem;
    font-weight: bold;
    text-shadow: 0 1px 2px rgba(0,0,0,0.1);
    transition: opacity 0.3s ease;
    user-select: none;
  }
  
  .spin-button-icon {
    width: 25px;
    height: 25px;
    user-select: none;
  }
  
  .drag-text {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 1rem;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-shadow: 0 1px 2px rgba(0,0,0,0.1);
    user-select: none;
  }