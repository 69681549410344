@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&family=Tiny5&display=swap');

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Black.ttf') format('truetype');
  src: url('./assets/fonts/Avenir-Black.woff') format('woff');
}

@font-face {
  font-family: 'Jumpdrop';
  src: url('./assets/fonts/GUMDROP.ttf') format('truetype');
}

@font-face {
  font-family: 'Frozito';
  src: url('./assets/fonts/Frozito-eZ99g.ttf') format('truetype');
}

@font-face {
  font-family: 'Wonderbar';
  src: url('./assets/fonts/Wonderbar-pALD.ttf') format('truetype');
}

@font-face {
  font-family: 'Fourkid';
  src: url('./assets/fonts/Fourkid-K7rEp.ttf') format('truetype');
}

@font-face {
  font-family: 'Bomb';
  src: url('./assets/fonts/The\ Bomb\ Sound.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

/* Add to your index.css */
html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling */
  position: fixed; /* Lock the viewport */
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Sora', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('./assets/images/bg.png') repeat-x;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100vw;
  width: 100vw;
  touch-action: none; /* Disable touch scrolling */
  overflow: hidden;
  margin: 0;
  /* Remove overflow properties here since we're locking scroll */
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
